@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.coming-soon-page {
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  background-color: #00205B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.content {
  z-index: 1;
}

.coming-soon-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(0,32,91,0.9) 70%);
  z-index: 0;
}

.coming-soon-page h1 {
  font-size: 48px;
  margin-bottom: 20px;
  font-weight: 700;
}

.coming-soon-page p {
  font-size: 20px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .coming-soon-page h1 {
    font-size: 36px;
  }

  .coming-soon-page p {
    font-size: 18px;
  }
}
