/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1000px;
    margin: 50px auto;
    padding: 20px;
}

/* Contact Section */
.contact-section {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.contact-section h2 {
    font-size: 2.5rem;
    color: #00205B;
    margin-bottom: 20px;
}

.contact-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.contact-item {
    background-color: #f9f9f9;
    flex: 1 1 300px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.icon-container {
    margin-bottom: 15px;
}

.icon {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 10px;
}

.contact-item p {
    font-size: 1rem;
    margin: 10px 0 0;
}

.contact-item a {
    color: #00205B;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-item a:hover {
    color: #003580;
}
