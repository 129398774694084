.search-toolbar {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Center align the logo and search input vertically */
    margin: 20px 0;

  }
  
  .search-logo {
    height: 40px; /* Adjust the height as needed */
    margin-right: 10px; /* Space between the logo and search input */
    margin-top: 8px;
  }

  .search-box {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    padding: 5px 10px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    width: 50%; /* Adjust width as necessary */
    max-width: 600px;
    margin-top: 0;
}

.search-box input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    background-color: #ffffff;
}

.search-box button {
    background: none;
    border: none;
    cursor: pointer;
}

.search-box button img {
    width: 20px; /* Adjust the icon size as necessary */
}