.claim-banner {
    background-color: #00205B; /* Main color */
    color: #ffffff;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .claim-message{
    font-weight: bold;
    padding-bottom: 8px;
    padding-right: 10px;
  }

  .claim-message2{
    font-size:8px;
    padding-right: 10px;
  }
  
  /* .claim-banner:hover {
    transform: translateY(-5px);
  } */
  
  .claim-banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .claim-banner p {
    margin: 0;
    font-size: 16px;
    color: #ffffff;
  }
  
  .claim-button {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00205B;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .claim-button:hover {
    background-color: #00205B;
    color: #ffffff;
  }
  