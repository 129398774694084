.result-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #00205B;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .result-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ccc;
  }

  .loading {
    color: #ccc;
  }
  
  .company-info, .product-info {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
  }
  
  .company-info {
    flex-direction: column;
  }
  
  .product-info {
    flex-direction: column;
  }
  
  .company-info .company-info-header, .product-info .product-info-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse; 
  }
  
  .company-info h2, .product-info h2 {
    font-size: 20px;
    margin-bottom: 10px;
    flex: 1; /* Allow the title to take up remaining space */
  }
  
  .company-image-box, .product-image-box {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 5%;
    margin-right: 10px; /* Adjust spacing */
    border: 2px solid #ccc;
    flex-shrink: 0; /* Do not allow shrinking */
  }
  
  .company-image-box img, .product-image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .info-section, .address-section, .postal-address-section, .additional-info-section {
    margin-bottom: 20px;
  }
  
  .info-section p, .address-section p, .postal-address-section p, .additional-info-section p {
    margin: 8px 0;
  }
  
  .info-section strong, .address-section strong, .postal-address-section strong, .additional-info-section strong {
    font-weight: bold;
  }
  
  .address-section h3, .postal-address-section h3, .additional-info-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .claim-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #00205B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right; /* Positioning the button to the right */
  }
  
  .claim-button:hover {
    background-color: #003580;
  }

  .info-table {
    width: 80%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .info-table th, .info-table td {
    padding: 5px;
    vertical-align: top;
  }
  
  .info-table th {
    text-align: right;
    font-weight:normal;
  }
  
  .info-table td {
    text-align: left;
    font-weight: bold;
  }

  .brand{
    font-size: 18px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .company-image-box, .product-image-box {
      width: 100px;
      height: 100px;
      margin-right: 10px; /* Adjust spacing for smaller screens */
    }
  }
  