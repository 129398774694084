/* styles.css */

/* Global Styles */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Header Section */
.header-section {
    background-color: #00205B;
    color: #ffffff;
    padding: 50px 20px;
    text-align: center;
}

.header-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.header-section p {
    font-size: 1.2rem;
    line-height: 1.8;
}

/* Main Sections */
.section {
    background-color: #ffffff;
    padding: 0px 20px;
    margin-bottom: 30px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.section h2 {
    font-size: 2.5rem;
    color: #00205B;
    margin-bottom: 20px;
    text-align: center;
}

.section p {
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: left;
}

.section ul {
    list-style-type: disc;
    padding-left: 20px;
}

.section ul li {
    font-size: 1.1rem;
    line-height: 1.8;
}

.section a {
    display: inline-block;
    background-color: #00205B;
    color: #ffffff;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.section a:hover {
    background-color: #003580;
}

.footer {
    background-color: #f1f1f1;
    padding: 20px 0;
    font-size: 14px;
    color: #555;
    border-top: 1px solid #e1e1e1;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-social {
    display: flex;
    align-items: center;
}

.footer-social span {
    margin-right: 10px;
}

.footer-social a {
    color: #555;
    margin-right: 10px;
    text-decoration: none;
    font-size: 18px;
}

.footer-social a:hover {
    color: #000;
}

.footer nav {
    display: flex;
    flex-wrap: wrap;
}

.footer nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px; /* Adjust based on screenshot spacing */
}

.footer nav ul li {
    margin-right: 20px;
    margin-bottom: 10px;
}

.footer nav ul li a {
    color: #555;
    text-decoration: none;
}

.footer nav ul li a:hover {
    text-decoration: underline;
}

.footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #e1e1e1;
    padding-top: 10px;
    margin-top: 10px;
}

.footer-info div {
    margin: 5px 0;
}

.footer-info a {
    color: #555;
    margin-right: 20px;
    text-decoration: none;
}

.footer-info a:hover {
    text-decoration: underline;
}


.follow-us-section {
    text-align: left;
    margin: 20px 0;
}

.follow-us-section p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.follow-us-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.follow-us-icons img {
    width: 30px;
    height: 30px;
    transition: transform 0.3s;
}

.follow-us-icons img:hover {
    transform: scale(1.1);
}

.product-hub {
    margin-right: 10px;
    padding-left: 10px;
}


.nav-column {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nav-column li {
    margin: 10px 0;
}

.nav-column li a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
}

.nav-column li a:hover {
    color: #007BFF; /* Change to your desired hover color */
}



.footer-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    align-items: center;
}

.footer-logo {
    font-size: 24px;
    font-weight: bold;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-links a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s;
}

.footer-links a:hover {
    color: #007BFF;
}