/* SearchBox.css */

.search-box-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative; /* Ensure absolute positioning can work */
    
}

.header {
    position: absolute;
    top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.about-link {
    text-decoration: none;
    color: black;
    font-size: 15px;
    margin-left: 20px;
    margin-top: 20px;
}

.profile-image {
    max-width: 40px; /* Maximum size */
    width: auto;
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%;
    margin-right: 20px;
    margin-top: 20px;
    object-fit: cover; 
}

.logo-container {
    margin-bottom: 20px; /* Adjust spacing as needed to move the logo up */
    margin-top: -100px;
}

.logo {
    width: 272px; /* Same as Google logo width */
    height: auto;
}

.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    padding: 5px 10px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    width: 50%; /* Adjust width as necessary */
    max-width: 600px;
    margin-top: 0;
    margin-top: -30px;
}

.search-box input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
}

.search-box button {
    background: none;
    border: none;
    cursor: pointer;
}

.search-box button img {
    width: 20px; /* Adjust the icon size as necessary */
}

.sign-in-button {
    background-color:  #00205B;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-right: 20px;
}


